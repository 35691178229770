import { VISUAL_FLOW_MODULE_TYPES } from 'constants/studyDesign'
import { CONDITION_TYPES } from 'constants/conditionBuilder'

// filter out segments imported with library that are linked to modules or options that were deleted by user
const filterSegmentsToBeAdded = (segments, modules) =>
	segments.filter(segment =>
		segment.conditions.every(condition => {
			if ([CONDITION_TYPES.DATE, CONDITION_TYPES.LANGUAGE].includes(condition.type)) {
				return true
			}

			const conditionStudyObject = modules[condition.studyObject.id]

			if (conditionStudyObject === undefined) {
				return false
			}

			if (
				[
					VISUAL_FLOW_MODULE_TYPES.ALLOCATION,
					VISUAL_FLOW_MODULE_TYPES.A_CHOICE,
					VISUAL_FLOW_MODULE_TYPES.MAXDIFF,
					VISUAL_FLOW_MODULE_TYPES.RANKING,
				].includes(conditionStudyObject.type)
			) {
				return condition.left.every(idOption =>
					conditionStudyObject.definition.options.some(option => option.id === idOption),
				)
			}

			return true
		}),
	)

export default filterSegmentsToBeAdded
